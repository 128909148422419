import React, { useState } from 'react'
import { api } from '../api';
import { NavLink } from 'react-router-dom'

function Login() {

    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage('');

        try {
            const response = await api.post('/crm/logins', credentials);
            const { token } = response.data;
            localStorage.setItem('token-crm', token);
            window.location.href = '/';
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Произошла ошибка');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='h-screen grid justify-items-center items-center'>
            <div>
                <div className='flex justify-center mb-14 font-semibold'>
                    BEMS COLLECTION
                </div>
                <h2 className='text-center font-bold text-xl'>Вход через логин и пароль</h2>
                <p className='text-center text-sm text-gray-300 my-2'>Админ</p>
                {errorMessage && <div className="mb-4 text-red-500 text-center">{errorMessage}</div>}
                <form action="" onSubmit={handleSubmit}>
                    <input
                        className='w-full border-2 border-gray-300 p-2 rounded-lg mb-3'
                        type="text"
                        placeholder='Логин'
                        name='username'
                        value={credentials.username}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="password"
                        placeholder='Пароль'
                        className='w-full border-2 border-gray-300 p-2 rounded-lg mb-3'
                        name='password'
                        value={credentials.password}
                        onChange={handleChange}
                        required
                    />
                    <NavLink to={'/login_employee'} className={'flex justify-center underline text-blue-600 text-sm'}>
                        Сотрудники
                    </NavLink>
                    <button
                        className={`bg-blue-500 text-white text-sm font-medium w-full p-2 mt-7 rounded-md hover:bg-blue-600 transition-colors ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isLoading}
                        type='submit'
                    >
                        {isLoading ? 'Вход...' : 'Войти'}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default Login