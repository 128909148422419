import { Routes, Route, Navigate } from 'react-router-dom'
import { lazy } from 'react'
import Login from './pages/login';
import LoginEmployee from './pages/login_employee';
import EmployeeCreate from './pages/employee-create';
const Layout = lazy(() => import('./containers/Layout'));

function App() {

  // const ProtectedRoute = ({ children }) => {
  //   if (!localStorage.getItem('token-crm')) {
  //     return <Navigate to="/login" />;
  //   }

  //   return children;
  // };

  const token = localStorage.getItem('token-crm');
  const user = localStorage.getItem('name');

  return (
    <>
      <Routes>
        {/* <Route path='/login' element={<Login />} />
        <Route path='/*'
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          } /> */}
        <Route path='/*' element={<Layout />} />
        <Route path='/' element={token ? <Navigate to="/otdel_one/dashboard" /> : <Login />} />
        <Route path='/login_employee' element={<LoginEmployee />} />
        <Route path='/employee_create' element={user ? <EmployeeCreate /> : <LoginEmployee />} />
      </Routes>
    </>
  );
}

export default App;