import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../api'


const customerSlice = createSlice({
    name: 'customer-two',
    initialState: {
        customer: [],
    },
    reducers: {
        setCustomer: (state, action) => {
            state.customer = action.payload
        }
    }
})

export const { setCustomer } = customerSlice.actions
export default customerSlice.reducer

export const fetchCustomerTwo = () => async (dispatch) => {
    try {
        const response = await api.get('/two/customer/product')
        dispatch(setCustomer(response.data))
    } catch (error) {
        console.log(error);
    }
}