import { configureStore } from '@reduxjs/toolkit'
import profitOtchet from './slice/profit-otchet.js'
import expences from './slice/expences.js'
import productAndPaymentsSlice from './slice/product-and-payments.js'
import employees from './slice/employees.js'
import customer from './slice/customer.js'
import dailyhistory from './slice/daily-history.js'
import invoiceHistory from './slice/invoice-history.js'
import otdelOneProductAndPaymentsSlice from './slice-otdel-one/product-and-payments.js'
import otdelOneProfitOtchet from './slice-otdel-one/profit-otchet.js'
import otdelOneExpences from './slice-otdel-one/expences.js'
import otdelOneDailyhistory from './slice-otdel-one/daily-history.js'
import otdelOneEmployees from './slice-otdel-one/employees.js'
import otdelOneCustomer from './slice-otdel-one/customer.js'
import otdelOneInvoiceHistory from './slice-otdel-one/invoice-history.js'
import otdelTwoProductAndPaymentsSlice from './slice-otdel-two/product-and-payments.js'
import otdelTwoProfitOtchet from './slice-otdel-two/profit-otchet.js'
import otdelTwoExpences from './slice-otdel-two/expences.js'
import otdelTwoDailyhistory from './slice-otdel-two/daily-history.js'
import otdelTwoEmployees from './slice-otdel-two/employees.js'
import otdelTwoCustomer from './slice-otdel-two/customer.js'
import otdelTwoInvoiceHistory from './slice-otdel-two/invoice-history.js'

const combinedReducer = {
    productandpayments: productAndPaymentsSlice,
    profitOtchet,
    expences,
    employees,
    customer,
    dailyhistory,
    invoiceHistory,
    otdelOneProductandpayments: otdelOneProductAndPaymentsSlice,
    otdelOneProfitOtchet,
    otdelOneExpences,
    otdelOneDailyhistory,
    otdelOneEmployees,
    otdelOneCustomer,
    otdelOneInvoiceHistory,
    otdelTwoProductandpayments: otdelTwoProductAndPaymentsSlice,
    otdelTwoProfitOtchet,
    otdelTwoExpences,
    otdelTwoDailyhistory,
    otdelTwoEmployees,
    otdelTwoCustomer,
    otdelTwoInvoiceHistory,
}

export default configureStore({
    reducer: combinedReducer
})