import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../api'


const expencesSlice = createSlice({
    name: 'expences-all-data',
    initialState: {
        expences: [],
    },
    reducers: {
        setExpences(state, action) {
            state.expences = action.payload
        }
    },
})

export const { setExpences } = expencesSlice.actions
export default expencesSlice.reducer

export const fetchExpencesDatas = () => async (dispatch) => {
    try {
        const response = await api.get('/expences-all-datas')
        dispatch(setExpences(response.data))
    } catch (error) {
        console.log(error);
    }
}

