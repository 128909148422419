import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../api'


const InvoiceHistorySlice = createSlice({
    name: 'invoice-history',
    initialState: {
        invoice: [],
    },
    reducers: {
        setInvoice: (state, action) => {
            state.invoice = action.payload
        }
    }
})

export const { setInvoice } = InvoiceHistorySlice.actions
export default InvoiceHistorySlice.reducer

export const fetchInovoiceHistory = () => async (dispatch) => {
    try {
        const response = await api.get('/schet-two/archive')
        dispatch(setInvoice(response.data))
    } catch (error) {
        console.log(error);
    }
}