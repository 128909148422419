import { createSlice } from '@reduxjs/toolkit'
import { api } from '../../api'


const employeesSlice = createSlice({
    name: 'employees-two',
    initialState: {
        employees: [],
    },
    reducers: {
        setPemployees: (state, action) => {
            state.employees = action.payload
        }
    }
})

export const { setPemployees } = employeesSlice.actions
export default employeesSlice.reducer

export const fetchEmployeesTwo = () => async (dispatch) => {
    try {
        const response = await api.get('/two/employees/product')
        dispatch(setPemployees(response.data))
    } catch (error) {
        console.log(error);
    }
}