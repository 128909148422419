import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { api } from '../api';

function EmployeeCreate() {

    const [inputValues, setInputValues] = useState({});
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [responseData, setResponseData] = useState([]);
    const [isWorking, setIsWorking] = useState(false);
    console.log(isWorking);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [selectedQuantity, setSelectedQuantity] = useState('');

    const productAndPayments = useSelector(state => state.productandpayments.productAndPayments);

    const name = localStorage.getItem('name');
    const fetchData = useCallback(async () => {
        try {
            const response = await api.get(`/daily/personal/${name}`);
            if (response.data) {
                setResponseData(response.data.data);
                setIsWorking(response.data.isWorking)
                setInputValues(initializeInputValues(response.data.data));
                setSelectedClient(response.data.data[0]?.client || '');
                setSelectedModel(response.data.data[0]?.model || '');
                setSelectedQuantity(response.data.data[0]?.total_amount || '');
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleCheckIn = () => {
        if (!navigator.geolocation) {
            alert('Geolocation is not supported by your browser');
            return;
        }

        setLoading(true);

        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                const action = isWorking ? 'stop' : 'start';
                const employee = localStorage.getItem('name');
                const datas = { 'employee': employee, 'action': action, 'lat': latitude, 'lon': longitude };
                try {
                    const response = await api.post('/employees/check', datas);
                    setMessage(response.data.message);

                    window.location.reload();
                } catch (error) {
                    console.error('Error:', error);
                    setMessage('Something went wrong');
                } finally {
                    setLoading(false);
                }
            },
            (error) => {
                alert('Unable to retrieve your location');
                setLoading(false);
            }
        );
    };

    const initializeInputValues = useCallback((data) => {
        return data.reduce((acc, item) => {
            if (item.users) {
                item.users.forEach(elem => {
                    acc[elem._id] = {
                        type_of_work: elem.type_of_work || "",
                        price: elem.price || "",
                        quantity_made: elem.quantity_made || "",
                        total_quantity: elem.total_quantity || "",
                    };
                });
            }
            return acc;
        }, {});
    }, []);

    const handleInputChange = useCallback((id, field, value) => {
        setInputValues(prevState => {
            const updateValues = {
                ...prevState,
                [id]: {
                    ...prevState[id],
                    [field]: value !== '' ? value : '',
                },
            }
            if (field === 'type_of_work' && productAndPayments) {
                const finedDataObj = productAndPayments.flatMap(item => item.otchet)
                    .find(item => item[value]);
                if (finedDataObj) {
                    updateValues[id].price = finedDataObj[value];
                }
            } if (field === 'quantity_made') {
                updateValues[id].total_quantity = parseFloat(value) * parseFloat(updateValues[id].price);
            }
            return updateValues;
        });
    }, [productAndPayments]);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            await Promise.all(
                responseData.flatMap((item) => {
                    const updatedClient = { client: selectedClient, model: selectedModel, total_amount: selectedQuantity };
                    const updateUserPromises = item.users.map((elem) => {
                        const updatedData = Object.keys(inputValues[elem._id]).reduce((acc, key) => {
                            acc[key] = inputValues[elem._id][key] === '' ? '' : inputValues[elem._id][key] || elem[key];
                            return acc;
                        }, {});
                        return api.patch(`/daily/update/${elem._id}`, updatedData);
                    });
                    // return [api.patch(`/daily/update-client/${item._id}`, updatedClient), ...updateUserPromises];
                })
            );
            await fetchData();
            setInputValues((prevValues) => {
                const newValues = { ...prevValues };
                responseData.forEach((item) => {
                    item.users.forEach((elem) => {
                        newValues[elem._id].quantity_made = '';
                        newValues[elem._id].total_quantity = '';
                    });
                });
                return newValues;
            });

        } catch (error) {
            console.log(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    function logoutUser() {
        localStorage.clear();
        window.location.href = '/'
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {
                isWorking ?
                    <div>
                        {name ? (
                            <div className='grid justify-center w-full mt-12'>
                                <div className='flex justify-between items-end font-semibold mb-3'>
                                    <div>
                                        <h1 className='mb-5 font-semibold'>{selectedClient}</h1>
                                        <h2>Модель: {selectedModel} </h2>
                                        <h3>Кол-во шт: {selectedQuantity} </h3>
                                    </div>
                                    <button
                                        className="bg-slate-500 text-white px-2 py-1 rounded hover:bg-slate-600 transition duration-200"
                                        onClick={logoutUser}
                                    >
                                        Выйти
                                    </button>
                                </div>
                                <div className="">
                                    <table className="lg:min-w-max bg-white dark:bg-inherit rounded-md z-50 lg:w-full text-[6px] lg:text-[10px]">
                                        <thead className='bg-slate-400 rounded-md'>
                                            <tr className="text-white">
                                                {['Вид работы', 'Цена за ед.', 'Кол-во сделано', 'К оплате'].map(header => (
                                                    <th key={header} className="lg:py-2 py-1 px-2 lg:px-4">{header}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {responseData.map((item) =>
                                                item.users.map((elem) => (
                                                    <tr key={elem._id}>
                                                        <td className="py-2 px-4 border">{inputValues[elem._id]?.type_of_work || ''}</td>
                                                        <td className="py-2 px-4 border">{inputValues[elem._id]?.price || ''}</td>
                                                        <td className="py-2 px-4 border">
                                                            <input
                                                                value={inputValues[elem._id]?.quantity_made || ''}
                                                                onChange={(e) => handleInputChange(elem._id, 'quantity_made', e.target.value)}
                                                                type="text"
                                                                className="bg-transparent outline-none w-full"
                                                            />
                                                        </td>
                                                        <td className="py-2 px-4 border">
                                                            {inputValues[elem._id]?.total_quantity || ''}
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='flex justify-end mt-3'>

                                    <button className="px-2 py-1 rounded ml-1 bg-slate-500 hover:bg-slate-600 transition text-white" onClick={handleSubmit} disabled={isSubmitting}>
                                        {isSubmitting ? 'Сохранение...' : 'Сохранить'}
                                    </button>
                                    <button onClick={handleCheckIn} className='bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 transition duration-200 ml-1'>Завершить</button>
                                </div>
                            </div>
                        ) : ''}
                    </div> :
                    <button onClick={handleCheckIn} className='bg-green-500 text-white m-4 px-2 py-1 rounded hover:bg-green-600 transition duration-200'>Старт</button>
            }
        </>
    )
}

export default EmployeeCreate